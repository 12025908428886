// LevelModal.js
import React, { useEffect, useState } from 'react';
import { FaTrophy, FaMedal, FaCrown, FaGem, FaStar, FaTimes, FaShieldAlt, FaBolt, FaFire, FaHeart } from 'react-icons/fa';
import { getLevel } from '../api'; // Adjust the path as needed

const LevelModal = ({ isOpen, onClose }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
      fetchLevels();
    } else {
      const timer = setTimeout(() => setIsAnimating(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const fetchLevels = async () => {
    try {
      const levelData = await getLevel();
      if (Array.isArray(levelData)) {
        const formattedLevels = levelData.map((level, index) => {
          let icon;
          switch (index) {
            case 0:
              icon = <FaMedal color="#CD7F32" />;
              break;
            case 1:
              icon = <FaMedal color="#C0C0C0" />;
              break;
            case 2:
              icon = <FaTrophy color="#FFD700" />;
              break;
            case 3:
              icon = <FaCrown color="#E5E4E2" />;
              break;
            case 4:
              icon = <FaGem color="#B9F2FF" />;
              break;
            case 5:
              icon = <FaShieldAlt color="#FF6347" />;
              break;
            case 6:
              icon = <FaBolt color="#FFFF00" />;
              break;
            case 7:
              icon = <FaFire color="#FF4500" />;
              break;
            case 8:
              icon = <FaHeart color="#FF69B4" />;
              break;
            case 9:
              icon = <FaStar color="#ffd700" />;
              break;
            default:
              icon = <FaStar color="#ffd700" />;
          }
          return {
            name: level.name,
            requirements: ` ${level.balance_required} KC`,
            icon,
          };
        });
        setLevels(formattedLevels);
      }
    } catch (error) {
      console.error('Error fetching levels:', error);
    }
  };

  if (!isOpen && !isAnimating) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={{
        ...styles.modalContent,
        transform: isOpen ? 'translateY(0)' : 'translateY(100%)',
        opacity: isOpen ? 1 : 0,
      }}>
        <div style={styles.modalHeader}>
          <h2 style={styles.modalTitle}>
            <FaStar style={styles.titleIcon} /> Levels
          </h2>
          <button style={styles.closeButton} onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div style={styles.levelsContainer}>
          {levels.map((level, index) => (
            <div key={index} style={styles.levelItem}>
              <div style={styles.levelIcon}>{level.icon}</div>
              <div style={styles.levelInfo}>
                <span style={styles.levelName}>{level.name}</span>
                <span style={styles.levelRequirements}>{level.requirements}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center', // Center the modal vertically
    justifyContent: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: '#724f0a',
    borderRadius: '20px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
    maxWidth: '90%',
    width: '100%',
    transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
    maxHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-10vh', // Add margin to push the modal up
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '25px',
    borderBottom: '2px solid rgba(255, 255, 255, 0.1)',
    backgroundColor: '#724f0a',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  },
  modalTitle: {
    color: '#ffd700',
    fontSize: '24px',
    fontWeight: 'bold',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  titleIcon: {
    marginRight: '10px',
    color: '#ffd700',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    color: '#ffd700',
    transition: 'color 0.3s ease',
  },
  levelsContainer: {
    padding: '25px',
    overflowY: 'auto',
    flexGrow: 1,
  },
  levelItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    padding: '15px',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s ease',
  },
  levelIcon: {
    fontSize: '30px',
    marginRight: '20px',
  },
  levelInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  levelName: {
    fontWeight: 'bold',
    color: '#724f0a',
    fontSize: '18px',
    marginBottom: '5px',
  },
  levelRequirements: {
    fontSize: '14px',
    color: '#724f0a',
  },
};

export default LevelModal;
