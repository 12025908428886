import React from 'react';
import balanceIcon from '../../assets/tap.png';

const InfoBox = ({ localBalance, localPoints, localLevel, handleLevelClick }) => {
  return (
    <div style={styles.infoBox}>
      <div style={styles.infoItem}>
        <img src={balanceIcon} alt="Balance" style={styles.balanceIcon} />
        <div style={styles.balanceInfo}>
          <span style={styles.label}>Balance</span>
          <span style={styles.valueCoin}>{localBalance?.toLocaleString() || 0}</span>
          <span style={styles.valuePoints}>{localPoints?.toLocaleString() || 0}</span>
        </div>
      </div>
      <div style={styles.levelItem}>
        <span style={styles.label}>Level</span>
        <button style={styles.levelButton} onClick={handleLevelClick}>{localLevel?.toLocaleString() || 0}</button>
      </div>
    </div>
  );
};

const styles = {
  infoBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(45deg, rgba(255,255,255,0.15), rgba(255,255,255,0.25))',
    borderRadius: '12px',
    padding: '20px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
  },
  levelItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  balanceIcon: {
    width: '40px',
    height: '40px',
    marginRight: '10px',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  balanceInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  label: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#ffd700',
    textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
  },
  valueCoin: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#ffffff',
    textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
  },
  valuePoints: {
    fontSize: '12px',
    color: '#ffd700',
    textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
    marginTop: '5px',
  },
  levelButton: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#ffffff',
    background: '#6d4c41',
    border: 'none',
    marginTop: '5px',
    borderRadius: '8px',
    padding: '5px 10px',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  },
};

export default InfoBox;