import { ENDPOINTS } from '../config';

export const saveUserInfo = async (username, userId) => {
  try {
    const response = await fetch(ENDPOINTS.saveUsername, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, user_id: userId }),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getReferralInfo = async (userId) => {
  try {
    const response = await fetch(`${ENDPOINTS.getReferralInfo}${userId}/`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchUserBalance = async (userId) => {
  try {
    const response = await fetch(`${ENDPOINTS.userBalance}${userId}/`);
    const data = await response.json();
    return {
      balance: data.balance || 0,
      points: data.points || 0,
      level: data.level || 0,
    };
  } catch (error) {
    return { balance: 0, points: 0 };
  }
};

export const updateBalance = async (userId, amount) => {
  try {
    const response = await fetch(`${ENDPOINTS.updateBalance}${userId}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount: amount.toString() }),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getLevel = async () => {
  try {
    const response = await fetch(ENDPOINTS.getLevels);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getUserStats = async () => {
  try {
    const response = await fetch(ENDPOINTS.getUserStats);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching user stats:', error);
    throw error;
  }
};

export const fetchTasks = async (userId) => {
  try {
    const response = await fetch(`${ENDPOINTS.tasks}?user_id=${userId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }
};

export const claimTask = async (taskId, userId) => {
  try {
    const response = await fetch(`${ENDPOINTS.tasks}${taskId}/claim/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error claiming task:', error);
    throw error;
  }
};

export const checkclaimDailyBonus = async (userId) => {
  try {
    const response = await fetch(ENDPOINTS.dailyBonus, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId }),
    });
   
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to claim time range reward');
    }
   
    return await response.json();
  } catch (error) {
    console.error('Error claiming time range reward:', error);
    throw error;
  }
};


export const claimDailyBonus = async (userId) => {
  try {
    const response = await fetch(ENDPOINTS.dailyBonus, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId }),
    });
   
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to claim time range reward');
    }
   
    return await response.json();
  } catch (error) {
    console.error('Error claiming time range reward:', error);
    throw error;
  }
};

export const claimTimeRangeReward = async (userId) => {
  try {
    const response = await fetch(ENDPOINTS.timeRangeReward, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId }),
    });
   
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to claim time range reward');
    }
   
    return await response.json();
  } catch (error) {
    console.error('Error claiming time range reward:', error);
    throw error;
  }
};

export const getAutoTapSettings = async () => {
  try {
    const response = await fetch(ENDPOINTS.getAutoTap);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching auto tap settings:', error);
    throw error;
  }
};

export const getTapPlusBoosters = async () => {
  try {
    const response = await fetch(ENDPOINTS.getTapPlusBoosters);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching tap plus boosters:', error);
    throw error;
  }
};

export const activateAutoTap = async (userId) => {
  try {
    const response = await fetch(ENDPOINTS.activateAutoTap, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to activate auto tap');
    }

    return await response.json();
  } catch (error) {
    console.error('Error activating auto tap:', error);
    throw error;
  }
};

export const activateTapPlusBooster = async (userId, boosterId) => {
  try {
    const response = await fetch(ENDPOINTS.activateTapPlusBooster, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId, booster_id: boosterId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to activate tap plus booster');
    }

    return await response.json();
  } catch (error) {
    console.error('Error activating tap plus booster:', error);
    throw error;
  }
};

export const getActiveBoosters = async (userId) => {
  try {
    const response = await fetch(`${ENDPOINTS.getActiveBoosters}?user_id=${userId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching active boosters:', error);
    throw error;
  }
};

export const getTapBoosters = async (userId) => {
  try {
    const response = await fetch(`${ENDPOINTS.getTapBoosters}?user_id=${userId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.tap_plus.amount || 1; // Return 1 if the API returns nothing
  } catch (error) {
    console.error('Error fetching tap boosters:', error);
    return 1; // Return 1 as default if there's an error
  }
};
