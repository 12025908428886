import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import coinImage from '../assets/tap.png';
import bannerImage from '../assets/banners.png';
import { API_URL } from '../config';

const LoadingScreen = () => {
  const navigate = useNavigate();
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [rewardData, setRewardData] = useState(null);
  const [bonusInfo, setBonusInfo] = useState(null);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const [coinImageLoaded, setCoinImageLoaded] = useState(false);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();
    tg.expand();

    // Load background image
    const bgImg = new Image();
    bgImg.src = bannerImage;
    bgImg.onload = () => setBackgroundLoaded(true);

    // Load coin image
    const coinImg = new Image();
    coinImg.src = coinImage;
    coinImg.onload = () => setCoinImageLoaded(true);

    const timer = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(timer);
          checkDailyBonus();
          return 100;
        }
        return prevProgress + 0.5;
      });
    }, 50);

    return () => clearInterval(timer);
  }, []);

  const checkDailyBonus = async () => {
    try {
      const tg = window.Telegram.WebApp;
      const userId = tg.initDataUnsafe?.user?.id;
      if (!userId) {
        console.error('User ID not found in Telegram WebApp data');
        navigate('/home');
        return;
      }

      const response = await fetch(`${API_URL}/daily-bonus?user_id=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to check daily bonus');
      }

      const data = await response.json();
      if (data.can_claim) {
        setBonusInfo({
          amount: data.bonus_amount,
          points: data.bonus_points
        });
        setShowRewardModal(true);
      } else {
        navigate('/home');
      }
    } catch (error) {
      console.error('Error checking daily bonus:', error);
      navigate('/home');
    }
  };

  const handleClaimReward = async () => {
    try {
      const tg = window.Telegram.WebApp;
      const userId = tg.initDataUnsafe?.user?.id;
      
      if (!userId) {
        console.error('User ID not found in Telegram WebApp data');
        navigate('/home');
        return;
      }

      const response = await fetch(`${API_URL}/api/daily-bonus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId }),
      });

      if (!response.ok) {
        throw new Error('Failed to claim daily bonus');
      }

      const data = await response.json();
      setRewardData(data);
      setShowRewardModal(false);
      setTimeout(() => navigate('/home'), 3000); // Navigate after 3 seconds
    } catch (error) {
      console.error('Error claiming daily bonus:', error);
      navigate('/home');
    }
  };

  return (
    <div style={{
      ...styles.container,
      backgroundImage: backgroundLoaded ? `url(${bannerImage})` : 'none',
      backgroundColor: backgroundLoaded ? 'transparent' : '#724f0a', // Fallback color
    }}>
      <div style={styles.backgroundOverlay}></div>
      {!showRewardModal && !rewardData && (
        <div style={styles.loadingContainer}>
          {coinImageLoaded && (
            <img src={coinImage} alt="Loading" style={styles.loadingImage} />
          )}
          <div style={styles.loadingText}>Loading... {Math.round(loadingProgress)}%</div>
          <div style={styles.progressBar}>
            <div style={{ ...styles.progressFill, width: `${loadingProgress}%` }}></div>
          </div>
        </div>
      )}

      {showRewardModal && bonusInfo && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h2 style={styles.modalTitle}>🎉 Daily Reward Available! 🎉</h2>
            <p style={styles.modalText}>Your daily bonus is ready to claim:</p>
            <div style={styles.rewardDetails}>
              <div style={styles.rewardItem}>
                <img src={coinImage} alt="Coins" style={styles.rewardIcon} />
                <p style={styles.rewardText}><strong>{bonusInfo.amount}</strong> Coins</p>
              </div>
              <div style={styles.rewardItem}>
                <FontAwesomeIcon icon={faTrophy} style={styles.faIcon} />
                <p style={styles.rewardText}><strong>{bonusInfo.points}</strong> Points</p>
              </div>
            </div>
            <button style={styles.claimButton} onClick={handleClaimReward}>
              Claim Reward
            </button>
          </div>
        </div>
      )}

      {rewardData && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h2 style={styles.modalTitle}>🎉 Reward Claimed! 🎉</h2>
            <p style={styles.modalText}>{rewardData.message}</p>
            <div style={styles.rewardDetails}>
              <div style={styles.rewardItem}>
                <img src={coinImage} alt="Coins" style={styles.rewardIcon} />
                <p style={styles.rewardText}>
                  <strong>{rewardData.bonus_amount}</strong> Coins
                  <span style={styles.newTotal}>
                    <FontAwesomeIcon icon={faArrowUp} style={styles.faIconSmall} />
                    New Total: {rewardData.new_balance}
                  </span>
                </p>
              </div>
              <div style={styles.rewardItem}>
                <FontAwesomeIcon icon={faTrophy} style={styles.faIcon} />
                <p style={styles.rewardText}>
                  <strong>{rewardData.bonus_points}</strong> Points
                  <span style={styles.newTotal}>
                    <FontAwesomeIcon icon={faArrowUp} style={styles.faIconSmall} />
                    New Total: {rewardData.new_points}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    transition: 'background-image 0.3s ease-in-out',
  },
  backgroundOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(114, 79, 10, 0.7)',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    color: '#ffffff',
    padding: '20px',
    borderRadius: '15px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  loadingImage: {
    width: '100px',
    height: '100px',
    animation: 'pulse 2s infinite',
  },
  loadingText: {
    marginTop: '20px',
    fontSize: '24px',
    fontWeight: 'bold',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
  },
  progressBar: {
    width: '80%',
    height: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '10px',
    marginTop: '20px',
    overflow: 'hidden',
  },
  progressFill: {
    height: '100%',
    backgroundColor: '#ffd700',
    transition: 'width 0.3s ease-in-out',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  modalContent: {
    background: 'rgba(114, 79, 10, 0.9)',
    padding: '40px 30px',
    borderRadius: '15px',
    textAlign: 'center',
    width: '90%',
    maxWidth: '400px',
    color: '#fff',
    boxShadow: '0 10px 25px rgba(0, 0, 0, 0.5)',
  },
  modalTitle: {
    fontSize: '28px',
    color: '#FFD700',
    marginBottom: '20px',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
  },
  modalText: {
    fontSize: '18px',
    marginBottom: '20px',
    color: '#ffffff',
  },
  rewardDetails: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '30px',
  },
  rewardItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  rewardIcon: {
    width: '40px',
    height: '40px',
  },
  faIcon: {
    fontSize: '40px',
    color: '#FFD700',
  },
  faIconSmall: {
    fontSize: '16px',
    color: '#4CAF50',
    marginRight: '5px',
  },
  rewardText: {
    fontSize: '20px',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  newTotal: {
    fontSize: '14px',
    color: '#4CAF50',
    marginTop: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  claimButton: {
    padding: '12px 25px',
    fontSize: '18px',
    backgroundColor: '#FFD700',
    color: '#724f0a',
    border: 'none',
    borderRadius: '25px',
    cursor: 'pointer',
    fontWeight: 'bold',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
  },
  '@keyframes pulse': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.1)' },
    '100%': { transform: 'scale(1)' },
  },
};

export default LoadingScreen;