import React, { useState, useCallback, useEffect } from 'react';
import { FaWallet } from 'react-icons/fa';
import LevelModal from './homecomponents/LevelModal';
import CoinContainer from './homecomponents/CoinContainer';
import Modal from './homecomponents/Modal';
import WalletConnectModal from './homecomponents/WalletConnectModal';
import InfoBox from './homecomponents/InfoBox';
import ActionBox from './homecomponents/ActionBox';
import coinImage from '../assets/tap.png';
import pointerImage from '../assets/tap.png';
import boostIcon from '../assets/tap.png';
import bannerImage from '../assets/banners.png';
import { useNavigate } from 'react-router-dom';
import { saveUserInfo, fetchUserBalance, updateBalance, claimDailyBonus, claimTimeRangeReward } from './api';

const Coinsphere = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({ balance: 0, points: 0, level: 0 });
  const [localBalance, setLocalBalance] = useState(0);
  const [localPoints, setLocalPoints] = useState(0);
  const [localLevel, setLocalLevel] = useState(0);
  const [taps, setTaps] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showLevelModal, setShowLevelModal] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState('');
  const [dailyBonusCooldown, setDailyBonusCooldown] = useState(false);
  const [timeRangeRewardCooldown, setTimeRangeRewardCooldown] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    buttonText: ""
  });
  const [canClaimDailyBonus, setCanClaimDailyBonus] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  const fetchAndSetUserData = useCallback(async () => {
    if (!userId) return;
    try {
      const data = await fetchUserBalance(userId);
      setUserData(data);
      localStorage.setItem('userBalance', data.balance.toString());
      localStorage.setItem('userPoints', data.points.toString());
      localStorage.setItem('userLevel', data.level.toString());
      setLocalBalance(data.balance);
      setLocalPoints(data.points);
      setLocalLevel(data.level);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [userId]);

  useEffect(() => {
    const initializeApp = async () => {
      setIsLoading(true);
      const tg = window.Telegram.WebApp;
      tg.ready();
      tg.expand();
    
      const user = tg.initDataUnsafe.user;
      if (user) {
        const username = user.username || 'User';
        const userId = user.id.toString();
        setUsername(username);
        setUserId(userId);
        
        const storedBalance = localStorage.getItem('userBalance');
        const storedPoints = localStorage.getItem('userPoints');
        const storedLevel = localStorage.getItem('userLevel');
        if (storedBalance) setLocalBalance(parseInt(storedBalance, 10));
        if (storedPoints) setLocalPoints(parseInt(storedPoints, 10));
        if (storedLevel) setLocalLevel(parseInt(storedLevel, 10));
        
        await fetchAndSetUserData();
        
        try {
          await saveUserInfo(username, userId);
        } catch (error) {
          console.error('Error in user info save:', error);
        }
      }
      setIsLoading(false);
    };

    initializeApp();
  }, [fetchAndSetUserData]);
  
  useEffect(() => {
    if (!userId) return;
    const balanceUpdateInterval = setInterval(() => fetchAndSetUserData(), 10000);
    return () => clearInterval(balanceUpdateInterval);
  }, [userId, fetchAndSetUserData]);

  useEffect(() => {
    const bgImg = new Image();
    bgImg.src = bannerImage;
    bgImg.onload = () => setBackgroundLoaded(true);
  }, []);
  
  const handleCoinClick = useCallback(async (e, relativeX, relativeY, boosterAmount) => {
    e.preventDefault();
    e.stopPropagation();
    
    const updatedBalance = localBalance + boosterAmount;
    setLocalBalance(updatedBalance);
    localStorage.setItem('userBalance', updatedBalance.toString());
    
    try {
      const updatedData = await updateBalance(userId, updatedBalance);
      
      setTaps(prevTaps => [...prevTaps, { x: relativeX, y: relativeY, id: Date.now() }]);
      setProgress(prevProgress => (prevProgress + boosterAmount / 5) % 100);

      if (updatedData.level !== localLevel) {
        setLocalLevel(updatedData.level);
        localStorage.setItem('userLevel', updatedData.level.toString());
      }
    } catch (error) {
      console.error('Error updating balance:', error);
      setLocalBalance(localBalance);
      localStorage.setItem('userBalance', localBalance.toString());
    }
  }, [userId, localBalance, localLevel]);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setTaps([]);
    }, 500);
    return () => clearTimeout(timer);
  }, [taps]);

  const handleClaimClick = async () => {
    if (timeRangeRewardCooldown) return;
    
    setTimeRangeRewardCooldown(true);
    try {
      const result = await claimTimeRangeReward(userId);
      const newBalance = localBalance + result.reward_amount;
      const newPoints = localPoints + result.reward_points;
      setLocalBalance(newBalance);
      setLocalPoints(newPoints);
      localStorage.setItem('userBalance', newBalance.toString());
      localStorage.setItem('userPoints', newPoints.toString());
      if (result.new_level !== localLevel) {
        setLocalLevel(result.new_level);
        localStorage.setItem('userLevel', result.new_level.toString());
      }
      setUserData(prevData => ({
        ...prevData,
        balance: newBalance,
        points: newPoints,
        level: result.new_level
      }));
      setShowModal(true);
      setModalContent({
        title: "Time Range Reward Collected!",
        content: `You've received ${result.reward_amount} coins and ${result.reward_points} points as your time range reward.`,
        buttonText: "Awesome!"
      });
    } catch (error) {
      console.error('Failed to claim time range reward:', error);
      setShowModal(true);
      setModalContent({
        title: "Time Range Reward Unavailable",
        content: error.message || "You can't claim the time range reward right now. Try again later.",
        buttonText: "OK"
      });
    } finally {
      setTimeout(() => setTimeRangeRewardCooldown(false), 5000);
    }
  };

  const handleDailyBonusClick = async () => {
    if (dailyBonusCooldown || !canClaimDailyBonus) return;
    
    setDailyBonusCooldown(true);
    try {
      console.log('Claiming daily bonus for user:', userId);
      const result = await claimDailyBonus(userId);
      console.log('Daily bonus claim result:', result);
  
      if (result.can_claim === false) {
        setCanClaimDailyBonus(false);
        setShowModal(true);
        setModalContent({
          title: "Daily Bonus Not Available",
          content: result.message || "You can't claim the daily bonus right now. Try again later.",
          buttonText: "OK"
        });
      } else {
        const newBalance = localBalance + result.bonus_amount;
        const newPoints = localPoints + result.bonus_points;
        
        setLocalBalance(newBalance);
        setLocalPoints(newPoints);
  
        localStorage.setItem('userBalance', newBalance.toString());
        localStorage.setItem('userPoints', newPoints.toString());
        
        if (result.new_level !== localLevel) {
          setLocalLevel(result.new_level);
          localStorage.setItem('userLevel', result.new_level.toString());
        }
  
        setUserData(prevData => ({
          ...prevData,
          balance: newBalance,
          points: newPoints,
          level: result.new_level
        }));
  
        setCanClaimDailyBonus(false);
        setShowModal(true);
        setModalContent({
          title: "Daily Bonus Collected!",
          content: `You've received ${result.bonus_amount} coins and ${result.bonus_points} points as your daily bonus.`,
          buttonText: "Awesome!"
        });
      }
    } catch (error) {
      console.error('Failed to claim daily bonus:', error);
      setShowModal(true);
      setModalContent({
        title: "Daily Bonus Unavailable",
        content: error.message || "You can't claim the daily bonus right now. Try again later.",
        buttonText: "OK"
      });
    } finally {
      setTimeout(() => setDailyBonusCooldown(false), 5000);
    }
  };

  const checkDailyBonusAvailability = useCallback(async () => {
    try {
      const result = await claimDailyBonus(userId);
      setCanClaimDailyBonus(result.can_claim);
    } catch (error) {
      console.error('Error checking daily bonus availability:', error);
      setCanClaimDailyBonus(false);
    }
  }, [userId]);
  
  useEffect(() => {
    checkDailyBonusAvailability();
    const intervalId = setInterval(checkDailyBonusAvailability, 60000); // Check every minute
    return () => clearInterval(intervalId);
  }, [checkDailyBonusAvailability]);

  const handleLevelClick = () => {
    setShowLevelModal(true);
  };

  const handleBreedKittenClick = () => {
    const tg = window.Telegram.WebApp;
    tg.BackButton.show();
    navigate('/breed');
  };

  const handleConnectWallet = () => {
    setShowWalletModal(true);
  };

  if (isLoading) {
    return (
      <div style={styles.loadingContainer}>
        <div style={styles.loadingSpinner}></div>
        <p style={styles.loadingText}>Loading...</p>
      </div>
    );
  }

  return (
    <div style={{
      ...styles.container,
      backgroundImage: backgroundLoaded ? `url(${bannerImage})` : 'none',
      backgroundColor: backgroundLoaded ? 'transparent' : '#724f0a', // Fallback color
    }}>
      <div style={styles.backgroundOverlay}></div>
      <div style={styles.content}>
        <div style={styles.header}>
          <div style={styles.welcomeMessage}>Welcome, {username}</div>
          <div style={styles.connectWalletButton} onClick={handleConnectWallet}>
            <FaWallet style={styles.walletIcon} />
            Connect Wallet
          </div>
          <InfoBox
            localBalance={localBalance}
            localPoints={localPoints}
            localLevel={localLevel}
            handleLevelClick={handleLevelClick}
          />
        </div>
        <CoinContainer
          progress={progress}
          setProgress={setProgress}
          handleCoinClick={handleCoinClick}
          taps={taps}
          coinImage={coinImage}
          pointerImage={pointerImage}
          boostIcon={boostIcon}
        />
        <ActionBox
          handleClaimClick={handleClaimClick}
          handleDailyBonusClick={handleDailyBonusClick}
          timeRangeRewardCooldown={timeRangeRewardCooldown}
          dailyBonusCooldown={dailyBonusCooldown}
        />
        <button style={styles.claimButton} onClick={handleBreedKittenClick}>
          <img src={coinImage} alt="Tap" style={{...styles.buttonIcon, ...nonClickableImageStyle}} />
          Breed Kitten
        </button>
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title={modalContent.title}
        content={modalContent.content}
        buttonText={modalContent.buttonText}
      />
      <LevelModal isOpen={showLevelModal} onClose={() => setShowLevelModal(false)} />
      <WalletConnectModal
        isOpen={showWalletModal}
        onClose={() => setShowWalletModal(false)}
      />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#ffffff',
    fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    padding: '20px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    position: 'relative',
  },
  backgroundOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(114, 79, 10, 0.7)',
    backdropFilter: 'blur(1px)', 
  },
  content: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '20px',
    position: 'relative',
  },
  welcomeMessage: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '10px',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  connectWalletButton: {
    fontSize: '13px',
    fontWeight: 'bold',
    marginBottom: '10px',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
    position: 'absolute',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  walletIcon: {
    marginRight: '5px',
  },
  claimButton: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#ffffff',
    background: 'linear-gradient(45deg, rgba(255,255,255,0.15), rgba(255,255,255,0.25))',
    border: 'none',
    borderRadius: '8px',
    padding: '10px 20px',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    marginTop: '20px',
    width: '80%',
    maxWidth: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonIcon: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: 'linear-gradient(to bottom,#724f0a)',
  },
  loadingSpinner: {
    border: '4px solid #f3f3f3',
    borderTop: '4px solid #3498db',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
  },
  loadingText: {
    color: '#ffffff',
    marginTop: '20px',
    fontSize: '18px',
    fontWeight: 'bold',
  },
};

const nonClickableImageStyle = {
  pointerEvents: 'none',
  userSelect: 'none',
};

export default Coinsphere;
