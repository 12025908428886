import React, { useState, useEffect } from 'react';
import { FaLink, FaUser, FaCopy, FaTelegram, FaTimes } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import tapImage from '../assets/tap.png';
import { getReferralInfo, fetchUserBalance } from './api';

const BOT_USERNAME = process.env.REACT_APP_BOT_USERNAME;

const useTelegram = () => {
  const tg = window.Telegram.WebApp;
  const user = tg.initDataUnsafe?.user;
  return { tg, user };
};

const LoadingScreen = () => {
  return (
    <div style={styles.loadingContainer}>
      <div style={styles.loadingContent}>
        <img src={tapImage} alt="Loading" style={styles.loadingIcon} />
        <div style={styles.loadingText}>Loading referrals</div>
        <div style={styles.loadingDots}>
          <span style={styles.dot}></span>
          <span style={styles.dot}></span>
          <span style={styles.dot}></span>
        </div>
      </div>
    </div>
  );
};

const ReferralPage = () => {
  const [referralInfo, setReferralInfo] = useState({
    referralCode: '',
    referredUsers: [],
    referredUsersCount: 0
  });
  const [userBalance, setUserBalance] = useState({ balance: 0, points: 0 });
  const [referralLink, setReferralLink] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { tg, user } = useTelegram();

  useEffect(() => {
    if (tg.initDataUnsafe) {
      fetchData();
    } else {
      setError('Telegram Web App data not initialized');
    }
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [referralData, balanceData] = await Promise.all([
        getReferralInfo(user.id),
        fetchUserBalance(user.id)
      ]);
      console.log('Referral Data:', referralData); // For debugging
      setReferralInfo({
        referralCode: referralData.referral_code || '',
        referredUsers: referralData.referred_users || [],
        referredUsersCount: referralData.referred_users_count || 0
      });
      setUserBalance(balanceData);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch data');
      setLoading(false);
    }
  };

  const generateReferralLink = () => {
    const fullReferralLink = `https://t.me/${BOT_USERNAME}?start=${referralInfo.referralCode}`;
    setReferralLink(fullReferralLink);
    setShowModal(true);
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      toast.success("Referral link copied!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: 'linear-gradient(45deg, #724f0a, #9e6d0d)',
          color: '#ffd700',
          borderRadius: '10px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
          fontSize: '14px',
        },
        progressStyle: { background: '#ffd700' },
      });
    } catch (err) {
      console.error('Failed to copy: ', err);
      toast.error("Failed to copy. Please try again.", {
        // ... same style as success toast
      });
    }
  };

  const shareToTelegram = () => {
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent('Join using my referral link!')}`;
    window.open(telegramUrl, '_blank');
  };

  if (loading) return <LoadingScreen />;
  if (error) return <div style={styles.errorMessage}>{error}</div>;

  return (
    <div style={styles.pageContainer}>
      <div style={styles.contentWrapper}>
        <div style={styles.header}>
          <h1 style={styles.title}>Referrals</h1>
          <p style={styles.subtitle}>Invite your friends and earn for each successful referral!</p>
          <div 
            style={styles.availableReferralsContainer}
            onContextMenu={(e) => e.preventDefault()}
            onTouchStart={(e) => e.preventDefault()}
          >
            <img 
              src={tapImage} 
              alt="Available Referrals" 
              style={styles.availableReferralsIcon}
              draggable="false"
            />
            <span style={styles.availableReferralsText}>
              {referralInfo.referredUsersCount} Referral{referralInfo.referredUsersCount !== 1 ? 's' : ''}
            </span>
          </div>
        </div>
        
        <div style={styles.referredUsersContainer}>
          {referralInfo.referredUsers.length > 0 ? (
            referralInfo.referredUsers.map(user => (
              <div key={user.user_id} style={styles.referredUserItem}>
                <div style={styles.userIconContainer}>
                  <FaUser style={styles.userIcon} />
                </div>
                <div style={styles.userInfo}>
                  <span style={styles.userName}>{user.username}</span>
                  <span style={styles.userDescription}>Joined: {new Date(user.created_at).toLocaleDateString()}</span>
                </div>
              </div>
            ))
          ) : (
            <div style={styles.noReferralsMessage}>
              No referred users yet. Share your referral link to start earning!
            </div>
          )}
        </div>
        
        <button
          style={styles.referralButton}
          onClick={generateReferralLink}
        >
          <div style={styles.referralInfo}>
            <FaLink style={styles.referralIcon} />
            <span style={styles.referralTitle}>Generate Referral Link</span>
          </div>
        </button>
      </div>

      {showModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <button style={styles.closeButton} onClick={() => setShowModal(false)}>
              <FaTimes />
            </button>
            <h2 style={styles.modalTitle}>Your Referral Link</h2>
            <div style={styles.referralLinkContainer}>
              <p style={styles.referralLinkText}>{referralLink}</p>
            </div>
            <div style={styles.modalButtons}>
              <button style={styles.modalButton} onClick={copyToClipboard}>
                <FaCopy style={styles.modalButtonIcon} />
                Copy
              </button>
              <button style={styles.modalButton} onClick={shareToTelegram}>
                <FaTelegram style={styles.modalButtonIcon} />
                Share
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

const styles = {
  pageContainer: {
    backgroundColor: '#724f0a',
    minHeight: '100vh',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    margin: '0 auto',
    paddingBottom: '80px',
  },
  contentWrapper: {
    padding: '20px',
    flexGrow: 1,
    overflowY: 'auto',
  },
  header: {
    marginBottom: '15px',
  },
  title: {
    fontSize: 'clamp(22px, 5vw, 26px)',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#fff',
  },
  subtitle: {
    fontSize: 'clamp(14px, 3vw, 16px)',
    color: '#fff',
    marginBottom: '15px',
  },
  availableReferralsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
  },
  availableReferralsIcon: {
    width: 'clamp(40px, 10vw, 60px)',
    height: 'auto',
    marginRight: '15px',
    pointerEvents: 'none',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
  },
  availableReferralsText: {
    fontSize: 'clamp(18px, 4vw, 22px)',
    color: '#ffd700',
  },
  referredUsersContainer: {
    marginBottom: '20px',
    background: 'rgba(255,255,255,0.1)',
    borderRadius: '15px',
    padding: '10px',
  },
  referredUserItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.05)',
    padding: '10px',
    borderRadius: '10px',
    marginBottom: '8px',
  },
  userIconContainer: {
    backgroundColor: '#ffd700',
    borderRadius: '50%',
    width: 'clamp(30px, 8vw, 40px)',
    height: 'clamp(30px, 8vw, 40px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px',
  },
  userIcon: {
    color: '#724f0a',
    fontSize: 'clamp(16px, 4vw, 20px)',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  userName: {
    fontSize: 'clamp(16px, 3.5vw, 18px)',
    fontWeight: 'bold',
    color: '#fff',
    marginBottom: '3px',
  },
  userDescription: {
    fontSize: 'clamp(12px, 2.5vw, 14px)',
    color: '#ccc',
    marginBottom: '5px',
  },
  noReferralsMessage: {
    fontSize: 'clamp(14px, 3vw, 16px)',
    color: '#ccc',
    textAlign: 'center',
    padding: '20px',
  },
  referralButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(45deg, #FFD700, #FFA500)',
    color: '#724f0a',
    padding: '15px',
    borderRadius: '10px',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    textAlign: 'center',
    fontSize: 'clamp(16px, 3.5vw, 18px)',
    fontWeight: 'bold',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s ease-in-out',
  },
  referralInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  referralIcon: {
    fontSize: 'clamp(18px, 4vw, 20px)',
    marginRight: '10px',
  },
  referralTitle: {
    fontSize: 'clamp(16px, 3.5vw, 18px)',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: 'rgba(114, 79, 10, 0.95)',
    borderRadius: '20px',
    padding: '20px',
    width: '90%',
    maxWidth: '350px',
    position: 'relative',
    boxShadow: '0 10px 25px rgba(0, 0, 0, 0.5)',
    border: '2px solid #ffd700',
  },
  closeButton: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    color: '#ffd700',
    transition: 'transform 0.2s ease-in-out',
  },
  modalTitle: {
    color: '#ffd700',
    fontSize: '24px',
    marginBottom: '20px',
    textAlign: 'center',
    textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
  },
  referralLinkContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '10px',
    borderRadius: '10px',
    marginBottom: '20px',
    boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
    maxHeight: '60px',
    overflowY: 'auto',
  },
  referralLinkText: {
    fontSize: '14px',
    wordBreak: 'break-all',
    color: '#ffffff',
    textAlign: 'center',
    margin: 0,
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalButton: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    backgroundColor: '#ffd700',
    color: '#724f0a',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    fontSize: '14px',
    marginRight: '10px',
    fontWeight: 'bold',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  },
  modalButtonIcon: {
    marginRight: '5px',
    fontSize: '16px',
  },
  loadingContainer: {
    backgroundColor: '#724f0a',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingIcon: {
    width: '80px',
    height: '80px',
    animation: 'pulse 1.5s infinite ease-in-out',
  },
  loadingText: {
    color: '#fff',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '20px',
  },
  loadingDots: {
    display: 'flex',
    marginTop: '10px',
  },
  dot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: '#ffd700',
    margin: '0 5px',
    animation: 'bounce 1.4s infinite ease-in-out both',
  },
  errorMessage: {
    color: '#ff6b6b',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    margin: '20px',
  },
};

export default ReferralPage;