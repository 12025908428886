// Declare and export the API URL
export const API_URL = 'https://kictokenapi-ee2cd4ae6fb6.herokuapp.com/api';

export const ENDPOINTS = {
  saveUsername: `${API_URL}/save_username/`,
  tasks: `${API_URL}/tasks/`,
  userBalance: `${API_URL}/get_balance/`,
  updateBalance: `${API_URL}/update_balance/`,
  getReferralInfo: `${API_URL}/get_referral_info/`,
  dailyBonus: `${API_URL}/daily-bonus/`,
  getLevels: `${API_URL}/get_levels/`,
  timeRangeReward: `${API_URL}/time-range-reward/`,
  getAutoTap: `${API_URL}/get-auto-tap/`,
  activateAutoTap: `${API_URL}/activate-auto-tap/`,
  getTapPlusBoosters: `${API_URL}/get-tap-plus-boosters/`,
  activateTapPlusBooster: `${API_URL}/activate-tap-plus-booster/`,
  getActiveBoosters: `${API_URL}/get-active-boosters/`,
  getTapBoosters: `${API_URL}/get-tap-boosters/`, // Add this new endpoint
  getUserStats: `${API_URL}/get_user_stats/`,
};
