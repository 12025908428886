import React, { useState, useEffect } from 'react';
import { FaHandPointer, FaCoins, FaRocket, FaClock, FaTimes } from 'react-icons/fa';
import tapImage from '../assets/tap.png';
import {
  activateAutoTap,
  getAutoTapSettings,
  getTapPlusBoosters,
  activateTapPlusBooster,
  getActiveBoosters
} from './api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoadingScreen = () => {
  return (
    <div style={styles.loadingContainer}>
      <div style={styles.loadingContent}>
        <img src={tapImage} alt="Loading" style={styles.loadingIcon} />
        <div style={styles.loadingText}>Loading boosters</div>
        <div style={styles.loadingDots}>
          <span style={styles.dot}></span>
          <span style={styles.dot}></span>
          <span style={styles.dot}></span>
        </div>
      </div>
    </div>
  );
};

const BreedKittenPage = () => {
  const [userId, setUserId] = useState('');
  const [autoTapSettings, setAutoTapSettings] = useState(() => {
    const cached = localStorage.getItem('autoTapSettings');
    return cached ? JSON.parse(cached) : {
      name: 'Auto Tap',
      description: 'Automatically tap for you',
      activation_cost: 0
    };
  });
  const [tapPlusBoosters, setTapPlusBoosters] = useState(() => {
    const cached = localStorage.getItem('tapPlusBoosters');
    return cached ? JSON.parse(cached) : [];
  });
  const [activeBoosters, setActiveBoosters] = useState(() => {
    const cached = localStorage.getItem('activeBoosters');
    return cached ? JSON.parse(cached) : {};
  });
  const [selectedBooster, setSelectedBooster] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();
    tg.expand();

    const initDataUnsafe = tg.initDataUnsafe || {};
    const user = initDataUnsafe.user || {};

    if (user && user.id) {
      const storedUserId = localStorage.getItem('userId');
      if (storedUserId !== user.id.toString()) {
        // Clear cached data if user changed
        localStorage.clear();
        localStorage.setItem('userId', user.id.toString());
      }
      setUserId(user.id.toString());
      fetchData();
    } else {
      setError('Telegram Web App data not initialized');
      setLoading(false);
    }
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      await Promise.all([
        fetchAutoTapSettings(),
        fetchTapPlusBoosters(),
        fetchActiveBoosters(userId)
      ]);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch data');
      setLoading(false);
    }
  };

  const fetchAutoTapSettings = async () => {
    try {
      const settings = await getAutoTapSettings();
      setAutoTapSettings(settings);
      localStorage.setItem('autoTapSettings', JSON.stringify(settings));
    } catch (error) {
      console.error('Error fetching auto tap settings:', error);
    }
  };

  const fetchTapPlusBoosters = async () => {
    try {
      const boosters = await getTapPlusBoosters();
      setTapPlusBoosters(boosters);
      localStorage.setItem('tapPlusBoosters', JSON.stringify(boosters));
    } catch (error) {
      console.error('Error fetching tap plus boosters:', error);
    }
  };

  const fetchActiveBoosters = async (userId) => {
    try {
      const active = await getActiveBoosters(userId);
      setActiveBoosters(active);
      localStorage.setItem('activeBoosters', JSON.stringify(active));
    } catch (error) {
      console.error('Error fetching active boosters:', error);
    }
  };

  const handleBoosterClick = (booster) => {
    setSelectedBooster(booster);
  };

  const activateBooster = async () => {
    try {
      if (selectedBooster.name === autoTapSettings.name) {
        const result = await activateAutoTap(userId);
        console.log('Auto tap activated:', result);
        const updatedActiveBoosters = {
          ...activeBoosters,
          auto_tap: {
            active: true,
            end_time: result.auto_tap_end_time
          }
        };
        setActiveBoosters(updatedActiveBoosters);
        localStorage.setItem('activeBoosters', JSON.stringify(updatedActiveBoosters));
      } else {
        if (activeBoosters.tap_plus) {
          console.log('Deactivating existing Tap Plus booster');
        }

        const result = await activateTapPlusBooster(userId, selectedBooster.id);
        console.log('Tap plus booster activated:', result);
        const updatedActiveBoosters = {
          ...activeBoosters,
          tap_plus: {
            active: true,
            amount: result.tap_plus_amount,
            end_time: result.tap_plus_end_time
          }
        };
        setActiveBoosters(updatedActiveBoosters);
        localStorage.setItem('activeBoosters', JSON.stringify(updatedActiveBoosters));
      }
    } catch (error) {
      console.error('Error activating booster:', error);
      toast.error(error.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: 'linear-gradient(45deg, #724f0a, #9e6d0d)',
          color: '#ffd700',
          borderRadius: '10px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
          fontSize: '14px',
        },
        progressStyle: { background: '#ffd700' },
      });
    }

    setSelectedBooster(null);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();

      setActiveBoosters(prevBoosters => {
        const updatedBoosters = { ...prevBoosters };
        let changed = false;

        if (updatedBoosters.auto_tap && new Date(updatedBoosters.auto_tap.end_time) <= now) {
          delete updatedBoosters.auto_tap;
          changed = true;
        }

        if (updatedBoosters.tap_plus && new Date(updatedBoosters.tap_plus.end_time) <= now) {
          delete updatedBoosters.tap_plus;
          changed = true;
        }

        if (changed) {
          localStorage.setItem('activeBoosters', JSON.stringify(updatedBoosters));
        }

        return updatedBoosters;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatRemainingTime = (endTime) => {
    const now = new Date();
    const remainingTime = new Date(endTime) - now;

    if (remainingTime <= 0) return "Expired";

    const hours = Math.floor(remainingTime / (60 * 60 * 1000));
    const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const BoosterItem = ({ booster, onActivate, isActive }) => {
    const BoosterIcon = booster.icon || FaRocket;
    const isTapPlusBooster = autoTapSettings ? booster.name !== autoTapSettings.name : true;
    const isAnyTapPlusActive = activeBoosters.tap_plus?.active;
    const isDisabled = isActive || (isTapPlusBooster && isAnyTapPlusActive);

    return (
      <div style={styles.boosterItem}>
        <div style={styles.boosterIconContainer}>
          <BoosterIcon style={styles.boosterIcon} />
        </div>
        <div style={styles.boosterInfo}>
          <span style={styles.boosterTitle}>{booster.name}</span>
          <span style={styles.boosterDescription}>{booster.description}</span>
          <div style={styles.coinInfo}>
            <img src={tapImage} alt="Cost" style={styles.costIcon} />
            <span style={styles.coinAmount}>{booster.activation_cost}</span>
          </div>
        </div>
        <button
          style={{
            ...styles.actionButton,
            backgroundColor: isDisabled ? '#808080' : '#32CD32',
            color: 'white',
            cursor: isDisabled ? 'default' : 'pointer',
          }}
          onClick={onActivate}
          disabled={isDisabled}
        >
          {isActive ? 'Active' : isDisabled ? 'Unavailable' : 'Activate'}
        </button>
      </div>
    );
  };

  const ActiveBoosterItem = ({ title, description, endTime }) => {
    return (
      <div style={styles.activeBoosterItem}>
        <div style={styles.activeBoosterInfo}>
          <div style={styles.activeBoosterIconContainer}>
            <FaRocket style={styles.activeBoosterIcon} />
          </div>
          <div style={styles.activeBoosterDetails}>
            <span style={styles.activeBoosterTitle}>{title}</span>
            <span style={styles.activeBoosterDescription}>{description}</span>
          </div>
        </div>
        <div style={styles.activeBoosterTime}>
          <FaClock style={styles.clockIcon} />
          <span style={styles.remainingTime}>
            {formatRemainingTime(endTime)}
          </span>
        </div>
      </div>
    );
  };

  if (loading) return <LoadingScreen />;
  if (error) return <div style={styles.errorMessage}>{error}</div>;

  return (
    <div style={styles.pageContainer}>
      <ToastContainer />
      <div style={styles.contentWrapper}>
        <div style={styles.header}>
          <h1 style={styles.title}>Breed Kitten Boosters</h1>
          <p style={styles.subtitle}>Boost your kitten breeding with these powerful upgrades!</p>
          <div style={styles.availableBoostersContainer}>
            <img src={tapImage} alt="Available Boosters" style={styles.availableBoostersIcon} />
            <span style={styles.availableBoostersText}>
              {(autoTapSettings ? 1 : 0) + tapPlusBoosters.length} Boosters available
            </span>
          </div>
        </div>

        <div style={styles.boostersContainer}>
          {autoTapSettings && (
            <BoosterItem
              booster={autoTapSettings}
              onActivate={() => handleBoosterClick(autoTapSettings)}
              isActive={activeBoosters.auto_tap?.active}
            />
          )}
          {tapPlusBoosters.map((booster) => (
            <BoosterItem
              key={booster.id}
              booster={booster}
              onActivate={() => handleBoosterClick(booster)}
              isActive={activeBoosters.tap_plus?.active && activeBoosters.tap_plus?.amount === booster.tap_plus_amount}
            />
          ))}
        </div>

        <div style={styles.activeBoosters}>
          <h2 style={styles.activeBoostersTitle}>Active Boosters</h2>
          {Object.keys(activeBoosters).length === 0 ? (
            <p style={styles.noActiveBoosters}>No active boosters at the moment.</p>
          ) : (
            <>
              {activeBoosters.auto_tap && (
                <ActiveBoosterItem
                  title={autoTapSettings.name}
                  description={autoTapSettings.description}
                  endTime={activeBoosters.auto_tap.end_time}
                />
              )}
              {activeBoosters.tap_plus && (
                <ActiveBoosterItem
                  title={`Tap +${activeBoosters.tap_plus.amount}`}
                  description={`Increase tap power by ${activeBoosters.tap_plus.amount}`}
                  endTime={activeBoosters.tap_plus.end_time}
                />
              )}
            </>
          )}
        </div>
      </div>

      {selectedBooster && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h2 style={styles.modalTitle}>{selectedBooster.name}</h2>
            <p style={styles.modalText}>
              Activate this booster?
            </p>
            <p style={styles.modalText}>Cost: {selectedBooster.activation_cost} coins</p>
            <button style={styles.activateButton} onClick={activateBooster}>Activate</button>
            <button style={styles.cancelButton} onClick={() => {
              setSelectedBooster(null);
            }}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  pageContainer: {
    backgroundColor: '#724f0a',
    minHeight: '100vh',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    margin: '0 auto',
    paddingBottom: '80px',
  },
  contentWrapper: {
    padding: '20px',
    flexGrow: 1,
    overflowY: 'auto',
  },
  header: {
    marginBottom: '15px',
  },
  title: {
    fontSize: 'clamp(22px, 5vw, 26px)',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#fff',
  },
  subtitle: {
    fontSize: 'clamp(14px, 3vw, 16px)',
    color: '#fff',
    marginBottom: '15px',
  },
  availableBoostersContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    pointerEvents: 'none', // This will make the entire container non-interactive
  },

  availableBoostersIcon: {
    width: 'clamp(40px, 10vw, 60px)',
    height: 'auto',
    marginRight: '15px',
    userSelect: 'none', // Prevents text selection
    WebkitUserDrag: 'none', // Prevents dragging in WebKit browsers
    KhtmlUserDrag: 'none', // For old versions of Konqueror browsers
    MozUserDrag: 'none', // For Firefox
    OUserDrag: 'none', // For old versions of Opera
    userDrag: 'none', // Standard syntax
  },

  availableBoostersText: {
    fontSize: 'clamp(18px, 4vw, 22px)',
    color: '#ffd700',
  },
  boostersContainer: {
    marginBottom: '20px',
    background: 'rgba(255,255,255,0.1)',
    borderRadius: '15px',
    padding: '10px',
  },
  boosterItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.05)',
    padding: '10px',
    borderRadius: '10px',
    marginBottom: '8px',
    flexWrap: 'wrap',
  },
  boosterIconContainer: {
    backgroundColor: '#ffd700',
    borderRadius: '50%',
    width: 'clamp(30px, 8vw, 40px)',
    height: 'clamp(30px, 8vw, 40px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px',
  },
  boosterIcon: {
    color: '#724f0a',
    fontSize: 'clamp(16px, 4vw, 20px)',
  },
  boosterInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 calc(100% - 160px)',
    minWidth: '150px',
  },
  boosterTitle: {
    fontSize: 'clamp(16px, 3.5vw, 18px)',
    fontWeight: 'bold',
    color: '#fff',
    marginBottom: '3px',
  },
  boosterDescription: {
    fontSize: 'clamp(12px, 2.5vw, 14px)',
    color: '#ccc',
    marginBottom: '5px',
  },
  coinInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  costIcon: {
    width: 'clamp(15px, 2.5vw, 17px)',
    height: 'auto',
    marginRight: '3px',
    pointerEvents: 'none', // This will make the icon non-interactive
    userSelect: 'none',
    WebkitUserDrag: 'none',
    KhtmlUserDrag: 'none',
    MozUserDrag: 'none',
    OUserDrag: 'none',
    userDrag: 'none',
  },

  coinAmount: {
    fontSize: 'clamp(12px, 2.5vw, 14px)',
    color: '#ffd700',
    fontWeight: 'bold',
  },
  actionButton: {
    padding: '8px 16px',
    borderRadius: '20px',
    fontSize: 'clamp(14px, 3vw, 16px)',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '80px',
    marginLeft: 'auto',
    marginTop: '10px',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: 'rgba(114, 79, 10, 0.9)', // Dark brown to match the theme
    padding: '25px',
    borderRadius: '15px',
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
    maxWidth: '90%',
    width: '300px',
  },
  modalTitle: {
    color: '#ffd700', // Gold color to match other titles
    fontSize: 'clamp(22px, 5vw, 26px)',
    marginBottom: '15px',
  },
  modalText: {
    color: '#ffffff', // White text for better readability
    fontSize: 'clamp(16px, 3.5vw, 18px)',
    marginBottom: '20px',
  },
  activateButton: {
    background: 'linear-gradient(45deg, #ffd700, #ffaa00)',
    border: 'none',
    borderRadius: '20px',
    padding: '10px 20px',
    fontSize: 'clamp(14px, 3vw, 16px)',
    fontWeight: 'bold',
    color: '#724f0a',
    cursor: 'pointer',
    marginRight: '10px',
  },
  cancelButton: {
    background: 'linear-gradient(45deg, #ff6347, #ff4500)',
    border: 'none',
    borderRadius: '20px',
    padding: '10px 20px',
    fontSize: 'clamp(14px, 3vw, 16px)',
    fontWeight: 'bold',
    color: '#ffffff',
    cursor: 'pointer',
  },
  activeBoosters: {
    marginTop: '20px',
    background: 'rgba(255,255,255,0.1)',
    borderRadius: '15px',
    padding: '15px',
  },
  activeBoostersTitle: {
    fontSize: 'clamp(20px, 4.5vw, 24px)',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#ffd700',
  },
  noActiveBoosters: {
    fontSize: 'clamp(14px, 3vw, 16px)',
    color: '#ccc',
    fontStyle: 'italic',
  },
  activeBoosterItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid rgba(255,255,255,0.1)',
    marginBottom: '10px',
    flexWrap: 'wrap',
  },
  activeBoosterInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    marginBottom: '10px',
  },
  activeBoosterIconContainer: {
    backgroundColor: '#ffd700',
    borderRadius: '50%',
    width: 'clamp(30px, 8vw, 40px)',
    height: 'clamp(30px, 8vw, 40px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px',
  },
  activeBoosterIcon: {
    color: '#724f0a',
    fontSize: 'clamp(16px, 4vw, 20px)',
  },
  activeBoosterDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  activeBoosterTitle: {
    fontSize: 'clamp(16px, 3.5vw, 18px)',
    fontWeight: 'bold',
    color: '#fff',
    marginBottom: '3px',
  },
  activeBoosterDescription: {
    fontSize: 'clamp(12px, 2.5vw, 14px)',
    color: '#ccc',
  },
  activeBoosterTime: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.1)',
    padding: '5px 10px',
    borderRadius: '10px',
  },
  clockIcon: {
    color: '#ffd700',
    marginRight: '5px',
    fontSize: 'clamp(14px, 3vw, 16px)',
  },
  remainingTime: {
    fontSize: 'clamp(14px, 3vw, 16px)',
    color: '#ffd700',
    fontWeight: 'bold',
  },
  errorMessageContainer: {
    marginTop: '20px',
    backgroundColor: 'rgba(255, 69, 0, 0.2)',
    padding: '10px',
    borderRadius: '5px',
  },
  errorMessage: {
    color: '#ff4500',
    fontSize: 'clamp(14px, 3vw, 16px)',
    fontWeight: 'bold',
  },


  loadingContainer: {
    backgroundColor: '#724f0a',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingIcon: {
    width: '80px',
    height: '80px',
    animation: 'pulse 1.5s infinite ease-in-out',
  },
  loadingText: {
    color: '#fff',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '20px',
  },
  loadingDots: {
    display: 'flex',
    marginTop: '10px',
  },
  dot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: '#ffd700',
    margin: '0 5px',
    animation: 'bounce 1.4s infinite ease-in-out both',
  },
  errorMessage: {
    color: '#ff4500',
    fontSize: 'clamp(14px, 3vw, 16px)',
    fontWeight: 'bold',
    padding: '20px',
  },
};

export default BreedKittenPage;