import React from 'react';

const Modal = ({ isOpen, onClose, title, content, buttonText }) => {
  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <h2 style={styles.modalTitle}>{title}</h2>
        <p style={styles.modalText}>{content}</p>
        <button style={styles.closeButton} onClick={onClose}>{buttonText}</button>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#5a3d08',
    padding: '25px',
    borderRadius: '15px',
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '300px',
    width: '90%',
  },
  modalTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  modalText: {
    fontSize: '14px',
    marginBottom: '20px',
  },
  closeButton: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#ffffff',
    background: '#6d4c41',
    border: 'none',
    borderRadius: '8px',
    padding: '5px 10px',
    cursor: 'pointer',
  },
};

export default Modal;