import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const WalletConnectModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose} aria-label="Close modal">×</CloseButton>
        <ModalTitle>Coming Soon</ModalTitle>
        <ModalText>Stay tuned for updates</ModalText>
      </ModalContent>
    </ModalOverlay>
  );
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;
`;

const ModalContent = styled.div`
  background-color: #5a3d08;
  padding: 30px;
  border-radius: 15px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  color: #ecf0f1;
  max-height: 80vh;
  overflow-y: auto;
  animation: ${slideIn} 0.3s ease-out;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: #ecf0f1;
  transition: color 0.3s ease;

  &:hover {
    color: #3498db;
  }
`;

const ModalTitle = styled.h2`
  font-size: 28px;
  margin-bottom: 15px;
  text-align: center;
  color: #fff;
`;

const ModalText = styled.p`
  font-size: 16px;
  margin-bottom: 25px;
  text-align: center;
  color: #fff;
`;

export default WalletConnectModal;
