import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCat, faCoins, faFire } from '@fortawesome/free-solid-svg-icons';
import './css/NavigationButtons.css';
import { fetchTasks } from './api'; // Assuming your API function is in 'api.js'

const Badge = ({ count }) => (
  <div className="badge">{count}</div>
);

const LOCAL_STORAGE_KEY = 'uncompletedTasks';
const EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds

const NavigationBar = () => {
  const [taskCount, setTaskCount] = useState(0); // State to hold uncompleted tasks count
  const [userId, setUserId] = useState(null); // State to store user ID from Telegram

  // Helper function to get task count from local storage
  const getTaskCountFromLocalStorage = () => {
    const data = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (data) {
      const { count, timestamp } = JSON.parse(data);
      const now = new Date().getTime();
      
      // Check if cached data has expired
      if (now - timestamp < EXPIRATION_TIME) {
        return count;
      }
    }
    return null;
  };

  // Helper function to save task count to local storage
  const saveTaskCountToLocalStorage = (count) => {
    const data = {
      count,
      timestamp: new Date().getTime(), // Store current time to handle expiration
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
  };

  useEffect(() => {
    // Initialize Telegram WebApp and get the user ID
    const initTelegram = () => {
      const tg = window.Telegram.WebApp;
      tg.ready();
      
      const user = tg.initDataUnsafe?.user; // Access user info from Telegram
      if (user && user.id) {
        setUserId(user.id); // Set the user ID from Telegram
      } else {
        console.error('Unable to fetch user ID from Telegram.');
      }
    };

    initTelegram(); // Initialize Telegram SDK

    // Check for cached task count in local storage
    const cachedTaskCount = getTaskCountFromLocalStorage();
    if (cachedTaskCount !== null) {
      setTaskCount(cachedTaskCount); // Use cached task count if available
    }

    // Fetch uncompleted tasks count after userId is set
    if (userId) {
      const fetchUncompletedTasks = async () => {
        try {
          const response = await fetchTasks(userId); // Fetch tasks using userId from Telegram
          const uncompletedTasksCount = response.uncompleted_tasks_count; // Get uncompleted tasks count from response
          setTaskCount(uncompletedTasksCount); // Update state with uncompleted task count
          saveTaskCountToLocalStorage(uncompletedTasksCount); // Save to local storage
        } catch (error) {
          console.error('Error fetching tasks:', error);
        }
      };

      fetchUncompletedTasks();
    }
  }, [userId]); // Re-run effect when userId changes

  return (
    <nav className="navbar">
      <ul className="nav-list">
        <li className="nav-item">
          <Link to="/home" className="nav-link">
            <FontAwesomeIcon icon={faCoins} />
            <span>Tap</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/task" className="nav-link">
            <div className="icon-badge-container">
              <FontAwesomeIcon icon={faFire} />
              <Badge count={taskCount} />
            </div>
            <span>Task</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/refferal" className="nav-link">
            <FontAwesomeIcon icon={faCat} />
            <span>Ref</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationBar;
