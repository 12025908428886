import React, { useState, useEffect, useRef } from 'react';
import { getTapBoosters, fetchUserBalance, getLevel } from '../api';

const CoinContainer = ({ handleCoinClick, coinImage, pointerImage, boostIcon }) => {
  const [boosterAmount, setBoosterAmount] = useState(1);
  const [userId, setUserId] = useState('');
  const [userBalance, setUserBalance] = useState(0);
  const [currentLevel, setCurrentLevel] = useState('');
  const [nextLevel, setNextLevel] = useState('');
  const [nextLevelBalance, setNextLevelBalance] = useState(0);
  const [progress, setProgress] = useState(0);
  const [levels, setLevels] = useState([]);
  const [taps, setTaps] = useState([]);
  const [coinOffset, setCoinOffset] = useState(0);
  
  const tappedRef = useRef(false);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();

    const initDataUnsafe = tg.initDataUnsafe || {};
    const user = initDataUnsafe.user;

    if (user) {
      const userId = user.id.toString();
      setUserId(userId);

      const fetchUserData = async () => {
        try {
          const [boosterAmount, userBalanceData, allLevels] = await Promise.all([
            getTapBoosters(userId),
            fetchUserBalance(userId),
            getLevel()
          ]);

          setBoosterAmount(boosterAmount);
          setUserBalance(userBalanceData.balance);
          setCurrentLevel(userBalanceData.level);
          setLevels(allLevels);

          if (allLevels && allLevels.length > 0) {
            updateLevelProgress(userBalanceData.balance, allLevels);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchUserData();
    } else {
      console.error('User data not available');
    }
  }, []);

  const updateLevelProgress = (balance, levels) => {
    if (!levels || levels.length === 0) {
      console.error('No levels defined');
      return;
    }

    const sortedLevels = [...levels].sort((a, b) => a.balance_required - b.balance_required);
    const currentLevelIndex = sortedLevels.findIndex(level => level.balance_required > balance);
    
    if (currentLevelIndex === -1) {
      // User is at max level
      const maxLevel = sortedLevels[sortedLevels.length - 1];
      setCurrentLevel(maxLevel.name);
      setNextLevel('Max Level');
      setNextLevelBalance(maxLevel.balance_required);
      setProgress(100);
    } else if (currentLevelIndex === 0) {
      // User is below the first level
      const firstLevel = sortedLevels[0];
      setCurrentLevel('Novice');
      setNextLevel(firstLevel.name);
      setNextLevelBalance(firstLevel.balance_required);
      setProgress((balance / firstLevel.balance_required) * 100);
    } else {
      const currentLevel = sortedLevels[currentLevelIndex - 1];
      const nextLevel = sortedLevels[currentLevelIndex];
      setCurrentLevel(currentLevel.name);
      setNextLevel(nextLevel.name);
      setNextLevelBalance(nextLevel.balance_required);

      const progressPercentage = ((balance - currentLevel.balance_required) / (nextLevel.balance_required - currentLevel.balance_required)) * 100;
      setProgress(Math.min(progressPercentage, 100));
    }
  };

  const handleInteractionStart = (e) => {
    e.preventDefault();
    tappedRef.current = false;
  };

  const handleInteractionEnd = (e) => {
    e.preventDefault();
    if (!tappedRef.current) {
      handleTap(e);
    }
  };

  const handleTouchCancel = () => {
    tappedRef.current = false;
  };

  const handleTap = (e) => {
    e.preventDefault();
    
    if (tappedRef.current) return;
    tappedRef.current = true;

    const coinWrapper = e.currentTarget;
    const rect = coinWrapper.getBoundingClientRect();
    
    let x, y;
    if (e.type.startsWith('touch')) {
      x = e.changedTouches[0].clientX - rect.left;
      y = e.changedTouches[0].clientY - rect.top;
    } else {
      x = e.clientX - rect.left;
      y = e.clientY - rect.top;
    }

    // Create a new tap effect
    const newTap = {
      id: Date.now(),
      x,
      y,
    };

    setTaps(prevTaps => [...prevTaps, newTap]);

    // Remove the tap effect after animation
    setTimeout(() => {
      setTaps(prevTaps => prevTaps.filter(tap => tap.id !== newTap.id));
    }, 500);

    // Update balance and progress
    const newBalance = userBalance + boosterAmount;
    setUserBalance(newBalance);
    if (levels && levels.length > 0) {
      updateLevelProgress(newBalance, levels);
    }

    // Call the external click handler if needed
    if (handleCoinClick) {
      handleCoinClick(e, x, y, boosterAmount);
    }

    // Add coin movement
    setCoinOffset(-10); // Move up
    setTimeout(() => setCoinOffset(0), 100); // Move back to original position

    // Reset the flag after a short delay
    setTimeout(() => {
      tappedRef.current = false;
    }, 100);
  };

  return (
    <div style={styles.coinContainer}>
      <div style={styles.progressRing}>
        <svg width="100%" height="100%" viewBox="0 0 320 320">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#ffd700" />
              <stop offset="100%" stopColor="#ff9900" />
            </linearGradient>
          </defs>
          <circle
            stroke="rgba(255, 255, 255, 0.2)"
            strokeWidth="10"
            fill="transparent"
            r="150"
            cx="160"
            cy="160"
          />
          <circle
            stroke="url(#gradient)"
            strokeWidth="10"
            fill="transparent"
            r="150"
            cx="160"
            cy="160"
            style={{
              strokeDasharray: `${2 * Math.PI * 150}`,
              strokeDashoffset: `${2 * Math.PI * 150 * (1 - progress / 100)}`,
            }}
          />
        </svg>
        <div
          style={{
            ...styles.coinWrapper,
            transform: `translate(-50%, calc(-50% + ${coinOffset}px))`,
            transition: 'transform 0.1s ease-out',
          }}
          onMouseDown={handleInteractionStart}
          onMouseUp={handleInteractionEnd}
          onTouchStart={handleInteractionStart}
          onTouchEnd={handleInteractionEnd}
          onTouchCancel={handleTouchCancel}
        >
          <img
            src={coinImage}
            alt="Coin"
            style={styles.coinImage}
            draggable="false"
          />
          {taps.map((tap) => (
            <div
              key={tap.id}
              style={{
                ...styles.tapIndicator,
                left: `${tap.x}px`,
                top: `${tap.y}px`,
              }}
            >
              +{boosterAmount}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  coinContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    flex: 1,
    justifyContent: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
  },
  progressRing: {
    width: '260px',
    height: '260px',
    position: 'relative',
  },
  coinWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
    WebkitTapHighlightColor: 'transparent',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
  },
  coinImage: {
    width: '300px',
    height: '300px',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
    pointerEvents: 'none',
  },
  tapIndicator: {
    position: 'absolute',
    color: 'gold',
    fontSize: '20px',
    fontWeight: 'bold',
    pointerEvents: 'none',
    animation: 'fadeOut 0.5s forwards',
    transform: 'translate(-50%, -50%)',
  },
  progressTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '20px',
  },
  progressText: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#ffffff',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
  },
  levelInfo: {
    fontSize: '14px',
    color: '#ffffff',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
    marginTop: '5px',
  },
  boostIcon: {
    position: 'absolute',
    right: '-40px',
    width: '40px',
    height: '40px',
  },
  progressPointer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '40px',
    height: '40px',
    transformOrigin: '50% 100%',
    transform: 'translate(-50%, -50%)',
  },
};

export default CoinContainer;