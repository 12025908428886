import React, { useState, useEffect } from 'react';
import { FaUsers, FaUserPlus, FaCoins } from 'react-icons/fa';
import tapImage from '../assets/tap.png';
import { getUserStats } from './api';

const StatsPage = () => {
  const [stats, setStats] = useState({
    daily_active_users: 0,
    total_users: 0,
    total_balance: 0,
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const cachedStats = localStorage.getItem('userStats');
        const cachedTime = localStorage.getItem('userStatsTime');
        const now = new Date().getTime();

        if (cachedStats && cachedTime && now - parseInt(cachedTime) < 5 * 60 * 1000) {
          setStats(JSON.parse(cachedStats));
        } else {
          const data = await getUserStats();
          setStats(data);
          localStorage.setItem('userStats', JSON.stringify(data));
          localStorage.setItem('userStatsTime', now.toString());
        }
      } catch (error) {
        console.error('Failed to fetch stats:', error);
        const cachedStats = localStorage.getItem('userStats');
        if (cachedStats) {
          setStats(JSON.parse(cachedStats));
        }
      }
    };

    fetchStats();
  }, []);

  const preventLongPress = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.header}>
        <h1 style={styles.title}>Statistics</h1>
        <p style={styles.subtitle}>View the latest statistics of our platform</p>
      </div>
      
      <div style={styles.contentContainer}>
        <div 
          style={styles.overviewContainer}
          onContextMenu={preventLongPress}
          onTouchStart={preventLongPress}
        >
          <img 
            src={tapImage} 
            alt="Overview" 
            style={styles.overviewIcon} 
            draggable="false"
          />
          <span style={styles.overviewText}>Key Metrics</span>
        </div>
        
        <div style={styles.statsContainer}>
          {[
            { icon: FaUserPlus, label: 'Daily Active Users', value: stats.daily_active_users },
            { icon: FaUsers, label: 'Total Users', value: stats.total_users },
            { icon: FaCoins, label: 'Total Balance', value: stats.total_balance },
          ].map((stat, index) => (
            <div key={index} style={styles.statItem}>
              <div style={styles.statIconContainer}>
                <stat.icon style={styles.statIcon} />
              </div>
              <div style={styles.statInfo}>
                <span style={styles.statLabel}>{stat.label}</span>
                <div 
                  style={styles.statValueContainer}
                  onContextMenu={preventLongPress}
                  onTouchStart={preventLongPress}
                >
                  <img 
                    src={tapImage} 
                    alt={stat.label} 
                    style={styles.statValueIcon} 
                    draggable="false"
                  />
                  <span style={styles.statValue}>{stat.value.toLocaleString()}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    backgroundColor: '#724f0a',
    height: '100vh',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#fff',
  },
  subtitle: {
    fontSize: '14px',
    color: '#fff',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  overviewContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
  },
  overviewIcon: {
    width: '80px',
    height: '80px',
    marginRight: '10px',
    pointerEvents: 'none',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
  },
  overviewText: {
    fontSize: '26px',
    color: '#ffd700',
  },
  statsContainer: {
    background: 'rgba(255,255,255,0.1)',
    borderRadius: '15px',
    padding: '15px',
  },
  statItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.05)',
    padding: '12px',
    borderRadius: '10px',
    marginBottom: '10px',
    transition: 'transform 0.2s ease-in-out',
    ':hover': {
      transform: 'translateY(-2px)',
    },
  },
  statIconContainer: {
    backgroundColor: '#ffd700',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px',
  },
  statIcon: {
    color: '#724f0a',
    fontSize: '20px',
  },
  statInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  statLabel: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff',
    marginBottom: '5px',
  },
  statValueContainer: {
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
  },
  statValueIcon: {
    width: '15px',
    height: '15px',
    marginRight: '5px',
    pointerEvents: 'none',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
  },
  statValue: {
    fontSize: '14px',
    color: '#ffd700',
    fontWeight: 'bold',
  },
};

export default StatsPage;