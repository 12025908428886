import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body {
    height: 100%;
    font-family: Arial, sans-serif;
  }

    @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.05);
      opacity: 1;
    }
    100% {
      transform: scale(0.95);
      opacity: 0.7;
    }
  }

  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }

  .app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    background-color: #724f0a;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  .main-content {
    flex: 1;
    overflow-y: auto;
    display: flex;
    padding-bottom: 70px;
    flex-direction: column;
  }

  .welcome-message {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .info-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    max-width: 500px;
    margin: 2rem auto;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 35px;
    padding: 1rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    transition: transform 0.3s ease;
  }

  .info-box:hover {
    transform: translateY(-5px);
  }

  .coin-container {
    position: relative;
    width: 50vh;
    height: 50vh;
    max-width: 90vw;
    max-height: 90vw;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .progress-ring {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .progress-pointer {
    position: absolute;
    width: 10%;
    height: 10%;
    top: 0;
    left: 50%;
    transform-origin: 50% 250%;
    transition: transform 0.35s ease;
  }

  .coin-display, .level-display {
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .coin-label, .level-label {
    font-size: 0.8rem;
    opacity: 0.8;
    margin-bottom: 0.2rem;
  }

  .coin-amount, .level-value {
    font-size: 1.4rem;
    color: #ffd700;
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
  }

  .coin-wrapper {
    position: relative;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    overflow: hidden;
    box-shadow:
      0 4px 20px rgba(0, 0, 0, 0.3),
      inset 0 0 15px rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .coin-wrapper:hover {
    transform: scale(1.05);
  }

  .coin-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  .tap-indicator {
    position: absolute;
    font-size: 1rem;
    font-weight: bold;
    color: #ffd700;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    animation: tap-fade 0.5s ease-out forwards;
  }

  @keyframes tap-fade {
    0% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
    100% {
      opacity: 0;
      transform: translateY(-30px) scale(1.5);
    }
  }

  @keyframes fadeOut {
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-20px); }
}

  .navigation-bar {
    height: 60px;
    background-color: #333;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: sticky;
    bottom: 0;
  }

  .nav-button {
    color: white;
    text-decoration: none;
    padding: 10px;
  }
`;

export default GlobalStyles;