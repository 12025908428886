import React from 'react';
import { FaGift, FaCalendarCheck } from 'react-icons/fa';

const ActionBox = ({ handleClaimClick, handleDailyBonusClick, timeRangeRewardCooldown, dailyBonusCooldown }) => {
  return (
    <div style={styles.actionBox}>
      <div style={styles.actionItem}>
        <FaGift style={styles.actionIcon} />
        <span style={styles.actionText}>Claim Reward</span>
        <button
          style={styles.actionButton}
          onClick={handleClaimClick}
          disabled={timeRangeRewardCooldown}
        >
          {timeRangeRewardCooldown ? 'Claiming...' : 'Claim'}
        </button>
      </div>
      <div style={styles.actionItem}>
        <FaCalendarCheck style={styles.actionIcon} />
        <span style={styles.actionText}>Daily Bonus</span>
        <button
          style={{...styles.actionButton, ...styles.collectedButton}}
          onClick={handleDailyBonusClick}
          disabled={true}
        >
          Collected
        </button>
      </div>
    </div>
  );
};

const styles = {
  actionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    marginTop: '20px',
  },
  actionItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '48%',
    background: 'linear-gradient(45deg, rgba(255,255,255,0.15), rgba(255,255,255,0.25))',
    borderRadius: '12px',
    padding: '10px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  },
  actionIcon: {
    fontSize: '20px',
    color: '#ffd700',
    marginBottom: '10px',
  },
  actionText: {
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#ffffff',
    marginBottom: '10px',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
  },
  actionButton: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#ffffff',
    background: '#6d4c41',
    border: 'none',
    borderRadius: '8px',
    padding: '5px 10px',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  },
  collectedButton: {
    background: '#4a4a4a',
    cursor: 'not-allowed',
  },
};

export default ActionBox;