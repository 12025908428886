import React, { useState, useEffect } from 'react';
import tapImage from '../assets/tap.png';
import { fetchTasks, claimTask } from './api';

const LoadingScreen = () => {
  return (
    <div style={styles.loadingContainer}>
      <div style={styles.loadingContent}>
        <img src={tapImage} alt="Loading" style={styles.loadingIcon} />
        <div style={styles.loadingText}>Loading tasks</div>
        <div style={styles.loadingDots}>
          <span style={styles.dot}></span>
          <span style={styles.dot}></span>
          <span style={styles.dot}></span>
        </div>
      </div>
    </div>
  );
};

const TaskPage = () => {
  const [taskData, setTaskData] = useState({ tasks: [], total_tasks: 0 });
  const [selectedTask, setSelectedTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [urlLoaded, setUrlLoaded] = useState({});
  const [countdowns, setCountdowns] = useState({});

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();
    tg.expand();

    const initData = tg.initData || '';
    const initDataUnsafe = tg.initDataUnsafe || {};
    const user = initDataUnsafe.user;

    if (user) {
      const username = user.username || 'User';
      const userId = user.id.toString();
      setUsername(username);
      setUserId(userId);
      fetchTasksData(userId);
    } else {
      setError('User data not available');
      setLoading(false);
    }

    const storedCountdowns = JSON.parse(localStorage.getItem('taskCountdowns')) || {};
    setCountdowns(storedCountdowns);
    
    const storedUrlLoaded = JSON.parse(localStorage.getItem('urlLoaded')) || {};
    setUrlLoaded(storedUrlLoaded);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const updatedCountdowns = { ...countdowns };
      let changed = false;

      Object.keys(updatedCountdowns).forEach(taskId => {
        const endTime = updatedCountdowns[taskId];
        if (endTime > now) {
          updatedCountdowns[taskId] = endTime;
        } else {
          delete updatedCountdowns[taskId];
          changed = true;
        }
      });

      if (changed) {
        setCountdowns(updatedCountdowns);
        localStorage.setItem('taskCountdowns', JSON.stringify(updatedCountdowns));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countdowns]);

  const fetchTasksData = async (userId) => {
    try {
      const data = await fetchTasks(userId);
      setTaskData(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const availableTasks = taskData.tasks.filter(task => !task.is_claimed).length;

  const handleTaskClick = (task) => {
    if (!task.is_claimed && !urlLoaded[task.id]) {
      setSelectedTask(task);
    }
  };

  const openExternalUrl = (url, taskId) => {
    window.open(url, '_blank');
    const newUrlLoaded = { ...urlLoaded, [taskId]: true };
    setUrlLoaded(newUrlLoaded);
    localStorage.setItem('urlLoaded', JSON.stringify(newUrlLoaded));
    setSelectedTask(null);
    
    const endTime = Date.now() + 30000;
    const newCountdowns = { ...countdowns, [taskId]: endTime };
    setCountdowns(newCountdowns);
    localStorage.setItem('taskCountdowns', JSON.stringify(newCountdowns));
  };

  const claimBonus = async (taskId) => {
    try {
      const result = await claimTask(taskId, userId);
      setTaskData(prevData => ({
        ...prevData,
        tasks: prevData.tasks.map(task => 
          task.id === taskId ? { ...task, is_claimed: true } : task
        )
      }));
      
      const updatedCountdowns = { ...countdowns };
      delete updatedCountdowns[taskId];
      setCountdowns(updatedCountdowns);
      localStorage.setItem('taskCountdowns', JSON.stringify(updatedCountdowns));
      
      const updatedUrlLoaded = { ...urlLoaded };
      delete updatedUrlLoaded[taskId];
      setUrlLoaded(updatedUrlLoaded);
      localStorage.setItem('urlLoaded', JSON.stringify(updatedUrlLoaded));
    } catch (error) {
      setError('Failed to claim task: ' + error.message);
    }
  };

  const getButtonText = (task) => {
    if (task.is_claimed) return 'Claimed';
    if (countdowns[task.id]) {
      const remainingTime = Math.max(0, Math.ceil((countdowns[task.id] - Date.now()) / 1000));
      return remainingTime > 0 ? `Wait ${remainingTime}s` : 'Claim';
    }
    return urlLoaded[task.id] ? 'Claim' : 'Start';
  };

  const isButtonDisabled = (task) => {
    if (task.is_claimed) return true;
    if (countdowns[task.id]) {
      return (countdowns[task.id] - Date.now()) > 0;
    }
    return false;
  };

  const handleButtonClick = (task) => {
    if (task.is_claimed) return;
    if (urlLoaded[task.id] || (countdowns[task.id] && countdowns[task.id] <= Date.now())) {
      claimBonus(task.id);
    } else {
      handleTaskClick(task);
    }
  };

  const sortedTasks = [...taskData.tasks].sort((a, b) => {
    if (a.is_claimed === b.is_claimed) return 0;
    return a.is_claimed ? 1 : -1;
  });

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <div style={styles.errorMessage}>Error: {error}</div>;
  }

  return (
    <div style={styles.pageContainer}>
      <div style={styles.contentWrapper}>
        <div style={styles.header}>
          <h1 style={styles.title}>Tasks</h1>
          <p style={styles.subtitle}>Welcome, {username}! We'll reward you immediately with TON points after each task completion!</p>
          <div style={styles.availableTasksContainer}>
            <img src={tapImage} alt="Available Tasks" style={styles.availableTasksIcon} />
            <span style={styles.availableTasksText}>{availableTasks} Tasks available</span>
          </div>
        </div>
        
        <div style={styles.tasksContainer}>
          {sortedTasks.map((task) => (
            <div key={task.id} style={{...styles.taskItem, opacity: task.is_claimed ? 0.6 : 1}}>
              <div style={styles.taskIconContainer}>
                <img src={tapImage} alt={task.heading} style={styles.taskIcon} />
              </div>
              <div style={styles.taskInfo}>
                <span style={styles.taskTitle}>{task.heading}</span>
                <span style={styles.taskDescription}>{task.subtitle}</span>
                <div style={styles.coinInfo}>
                  <img src={tapImage} alt="Reward" style={styles.rewardIcon} />
                  <span style={styles.coinAmount}>{task.balance_amount}</span>
                </div>
              </div>
              <button
                style={{
                  ...styles.actionButton,
                  backgroundColor: task.is_claimed ? '#808080' : 
                    (urlLoaded[task.id] || (countdowns[task.id] && countdowns[task.id] <= Date.now())) ? '#FFD700' : '#32CD32',
                  color: 'white',
                  border: 'none',
                  cursor: isButtonDisabled(task) ? 'default' : 'pointer',
                }}
                onClick={() => handleButtonClick(task)}
                disabled={isButtonDisabled(task)}
              >
                {getButtonText(task)}
              </button>
            </div>
          ))}
        </div>
      </div>
      
      {selectedTask && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <button 
              style={styles.closeIcon} 
              onClick={() => setSelectedTask(null)}
            >
              &#x2715;
            </button>
            <h2 style={styles.modalTitle}>{selectedTask.heading}</h2>
            <img src={tapImage} alt={selectedTask.heading} style={styles.modalIcon} />
            <p style={styles.modalDescription}>{selectedTask.subtitle}</p>
            <div style={styles.modalReward}>
              <img src={tapImage} alt="Reward" style={styles.modalRewardIcon} />
              <span style={styles.modalRewardAmount}>{selectedTask.balance_amount} points</span>
            </div>
            <button 
              style={styles.openUrlButton} 
              onClick={() => openExternalUrl(selectedTask.url, selectedTask.id)}
            >
              Start Task
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  pageContainer: {
    backgroundColor: '#724f0a',
    minHeight: '100vh',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    margin: '0 auto',
    paddingBottom: '80px',
  },
  contentWrapper: {
    padding: '20px',
    flexGrow: 1,
    overflowY: 'auto',
  },
  header: {
    marginBottom: '15px',
  },
  title: {
    fontSize: 'clamp(22px, 5vw, 26px)',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#fff',
  },
  subtitle: {
    fontSize: 'clamp(14px, 3vw, 16px)',
    color: '#fff',
    marginBottom: '15px',
  },
  availableTasksContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  availableTasksIcon: {
    width: 'clamp(40px, 10vw, 60px)',
    height: 'auto',
    marginRight: '15px',
  },
  availableTasksText: {
    fontSize: 'clamp(18px, 4vw, 22px)',
    color: '#ffd700',
  },
  tasksContainer: {
    marginBottom: '20px',
    background: 'rgba(255,255,255,0.1)',
    borderRadius: '15px',
    padding: '10px',
  },
  taskItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.05)',
    padding: '10px',
    borderRadius: '10px',
    marginBottom: '8px',
    flexWrap: 'wrap',
  },
  taskIconContainer: {
    backgroundColor: '#ffd700',
    borderRadius: '50%',
    width: 'clamp(30px, 8vw, 40px)',
    height: 'clamp(30px, 8vw, 40px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px',
    overflow: 'hidden',
  },
  taskIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  taskInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 calc(100% - 160px)',
    minWidth: '150px',
  },
  taskTitle: {
    fontSize: 'clamp(16px, 3.5vw, 18px)',
    fontWeight: 'bold',
    color: '#fff',
    marginBottom: '3px',
  },
  taskDescription: {
    fontSize: 'clamp(12px, 2.5vw, 14px)',
    color: '#ccc',
    marginBottom: '5px',
  },
  coinInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  rewardIcon: {
    width: 'clamp(15px, 2.5vw, 17px)',
    height: 'auto',
    marginRight: '3px',
  },
  coinAmount: {
    fontSize: 'clamp(12px, 2.5vw, 14px)',
    color: '#ffd700',
    fontWeight: 'bold',
  },
  actionButton: {
    padding: '8px 16px',
    borderRadius: '20px',
    fontSize: 'clamp(14px, 3vw, 16px)',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '80px',
    marginLeft: 'auto',
    marginTop: '10px',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: '#5a3d08',
    padding: '30px',
    borderRadius: '20px',
    textAlign: 'center',
    boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)',
    maxWidth: '90%',
    width: '350px',
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '24px',
    cursor: 'pointer',
    padding: '5px',
    lineHeight: 1,
  },
  modalTitle: {
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  modalIcon: {
    width: '80px',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '50%',
    marginBottom: '20px',
  },
  modalDescription: {
    color: 'white',
    fontSize: '16px',
    marginBottom: '20px',
    lineHeight: '1.5',
  },
  modalReward: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '25px',
  },
  modalRewardIcon: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  },
  modalRewardAmount: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#ffd700',
  },
  openUrlButton: {
    backgroundColor: '#6d4c41',
    border: 'none',
    borderRadius: '25px',
    padding: '12px 25px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'white',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    width: '100%',
  },
  errorMessage: {
    fontSize: '20px',
    color: '#ff6b6b',
    textAlign: 'center',
    marginTop: '50px',
  },
  loadingContainer: {
    backgroundColor: '#724f0a',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingIcon: {
    width: '80px',
    height: '80px',
    animation: 'pulse 1.5s infinite ease-in-out',
  },
  loadingText: {
    color: '#fff',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '20px',
  },
  loadingDots: {
    display: 'flex',
    marginTop: '10px',
  },
  dot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: '#ffd700',
    margin: '0 5px',
    animation: 'bounce 1.4s infinite ease-in-out both',
  },
};

export default TaskPage;