import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Coinsphre from './components/CoinSphere';
import Stats from './components/Stats';
import Referral from './components/refferal';
import Task from './components/Task';
import Breed from './components/Breed';
import NavigationBar from './components/NavigationButtons';
import LoadingScreen from './components/LoadingScreen';
import GlobalStyles from './GlobalStyles';

const TelegramAdapter = ({ children }) => {
  const [username, setUsername] = useState('');

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();
    // Immediately get the user's data
    const user = tg.initDataUnsafe.user;
    if (user) {
      setUsername(user.username || `${user.first_name} ${user.last_name}`.trim());
    }
    // Handle back button
    tg.BackButton.onClick(() => {
      tg.close();
    });
  }, []);

  return React.Children.map(children, child =>
    React.cloneElement(child, { username })
  );
};

const AppContent = () => {
  const location = useLocation();
  const showNavBar = location.pathname !== '/';

  return (
    <div className="app-container">
      <div className="main-content">
        <Routes>
          <Route path="/" element={<LoadingScreen />} />
          <Route path="/home" element={<Coinsphre />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/breed" element={<Breed />} />
          <Route path="/refferal" element={<Referral />} />
          <Route path="/task" element={<Task />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      {showNavBar && <NavigationBar />}
    </div>
  );
};

const App = () => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      setIsReady(true);
    } else {
      const script = document.createElement('script');
      script.src = 'https://telegram.org/js/telegram-web-app.js';
      script.async = true;
      script.onload = () => setIsReady(true);
      document.body.appendChild(script);
    }
  }, []);

  if (!isReady) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <GlobalStyles />
      <TelegramAdapter>
        <AppContent />
      </TelegramAdapter>
    </Router>
  );
};

export default App;